<template>
    <div id="chat-box" class="">
        <button
            id="btn-verstuur"
            class="btn btn-dark"
            @click="insertNewOpmerking()"
        >
            Verstuur <font-awesome-icon icon="paper-plane" />
        </button>
        <textarea placeholder="Typ hier een bericht of opmerking..." id="chat-box-input" cols="30" rows="10"></textarea>
        <div id="chat-box-scrollable-from-bottom">
            <div v-if="this.$store.state.opmerkingen.opmerkingen.length === 0">
                <p class="text-center">Er zijn nog geen opmerkingen of wijzigingen.</p>
            </div>
            <div v-else v-for="opmerking in this.$store.state.opmerkingen.opmerkingen" :key="opmerking.id">
                <chat-balloon :opmerking="opmerking"></chat-balloon>
            </div>
        </div>
    </div>
</template>

<script>

import { createToaster } from '@meforma/vue-toaster'
import ChatBalloon from '@/components/aanmeldingen/ChatBalloon.vue'
import moment from 'moment'
import 'moment/locale/nl'

export default {
  name: 'AanmeldingChat',
  components: {
    ChatBalloon
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      opmerkingen: []
    }
  },
  watch: {
    id: function () {
      this.fetchData()
    }
  },
  mounted () {
    this.fetchData()
  },
  created: function () {
    moment.locale('nl')
  },
  methods: {
    fetchData () {
      this.$store.dispatch('opmerkingen/getAll', this.id).then((res) => {
        console.log(res)
      })
    },
    moment: function () {
      return moment()
    },
    insertNewOpmerking () {
      const toaster = createToaster({ position: 'bottom-left', duration: 3000 })
      if (document.getElementById('chat-box-input').value === '') {
        toaster.error('Vul een opmerking in')
        return
      }
      const opmerking = {
        aanmeldingId: this.id,
        userId: this.$store.state.auth.userId,
        modified: moment().format('YYYY-MM-DDTHH:mm:ss'),
        opmerking: document.getElementById('chat-box-input').value
      }
      this.$store.dispatch('opmerkingen/insert', opmerking).then((res) => {
        if (res.status === 201) {
          document.getElementById('chat-box-input').value = ''
        }
      })
    }
  }
}
</script>

<style scoped>
    #chat-box{
        height: max(500px, calc(100vh - 134px));
        /* background: var(--background-light); */
        border-radius: 10px;
        padding: 0 12px;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        margin-bottom: 100px;
    }
    #chat-box-scrollable-from-bottom{
        display: flex;
        flex-direction: column-reverse;
        overflow-y: scroll;
        height: calc(100% - 120px);
        transition: height 300ms ease-in-out;
        padding-bottom: 24px;
    }
    #chat-box-input{
        border: none;
        border-radius: 10px;
        padding: 12px;
        background: #fff;
        resize: none;
        outline: none;
        height: 120px;
        transition: height 300ms ease-in-out;
        position: absolute;
        width: calc(100% - 24px);
        bottom: 12px;
        left: 12px;
        z-index: 1;
        padding-right: 120px;
    }
    #chat-box-input:focus{
        height: 200px;
    }

    #chat-box-input:focus ~ #chat-box-scrollable-from-bottom {
        height: calc(100% - 200px);
    }

    #btn-verstuur{
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 2;
    }
</style>
