<template>
    <div class='aanmeld-rij d-inline-flex w-100 justify-content-between'>
        <div class='aanmeld-rij-head'>
            <h6
            :class="isSameCursus"
            class='mb-0 mt-1'><strong>{{aanmelding.cursus.titel}}</strong></h6>
            {{aanmelding.skisnowboard.omschrijving}}
            &middot;
            {{aanmelding.preftime1.dag.description}}{{aanmelding.preftime1.dagdeel.periode}}
            <small> of {{aanmelding.preftime2.dag.description}}{{aanmelding.preftime2.dagdeel.periode}}</small>
        </div>
        <div class="text-center">

        </div>
        <div class="text-end">
          <strong>{{aanmelding.customer.firstname}}</strong> &middot; {{ leeftijd }}
          <br>
            <status-badge :status='aanmelding.aanmeldingAanmeldingStatusTypes[0]' :klant='klant'/>
        </div>

    </div>
</template>

<script>
import statusBadge from '@/components/aanmeldingen/StatusBadge.vue'
export default {
  name: 'AanmeldRij',
  components: {
    statusBadge
  },
  props: {
    aanmelding: {
      type: Object,
      required: true
    },
    klant: {
      type: Boolean,
      required: false,
      default: true
    },
    cursusId: {
      type: Number,
      required: false,
      default: null
    }
  },
  computed: {
    leeftijd: function () {
      const birthdate = new Date(this.aanmelding.customer.birthdate)
      const ageDifMs = Date.now() - birthdate.getTime()
      const ageDate = new Date(ageDifMs)
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },
    isSameCursus: function () {
      return this.aanmelding.cursus.id === this.cursusId ? 'same-cursus' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
    .aanmeld-rij{
        padding: 6px 12px;
        transition: all 50ms ease-in-out;
        border-radius: 10px;
        margin-bottom: 6px;
        background: #fff;
        cursor: pointer;
        // border-bottom: 1px solid rgba(255,255,255,.84);
        // box-shadow: 0px 0px 10px rgba(0,0,0,0.14);
    }
    .aanmeld-rij:last-child{
        border-bottom: none;
        margin-bottom: 0;
    }
    h6.same-cursus{
        color: var(--skipiste-color);
    }

</style>
