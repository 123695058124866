<template>
    <span
                :class='"badge status-badge status-badge-" + status.aanmeldingStatusTypeId'
            >
             {{klant ? status.aanmeldingStatusType.aanmeldingStatusTypeKlant : status.aanmeldingStatusType.aanmeldingStatusType1}}
             </span>
</template>

<script>
export default {
  name: 'statusBadge',
  props: {
    status: {
      type: Object,
      required: false,
      default: () => ({
        aanmeldingStatusTypeId: 'niet-gekoppeld',
        aanmeldingStatusType: {
          aanmeldingStatusType1: '',
          aanmeldingStatusTypeKlant: ''
        }
      })
    },
    klant: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>

<style scoped>
    .status-badge-1{
        color: #00A09F;
        background: #E6F4F1;
    }
    .status-badge-2{
        color: #0783C2;
        background: #a0c2d3;
    }
    .status-badge-3{
        color: #875B97;
        background: #e6e6e6;
    }
    .status-badge-4{
        color: #FF8D24;
        background: #FFF0E0;
    }
    .status-badge-5{
        color: rgba(0,0,0,.54);
        background: rgba(0,0,0,.05);
    }
    .status-badge-6{
        color: rgba(0,0,0,.54);
        background: rgba(0,0,0,.05);
    }
    .status-badge-7{
        color: #FF8D24;
        background: #FFF0E0;
    }
</style>
