<template>
    <loader-spinner v-if="loading"></loader-spinner>
    <div v-else class='px-md-3 pb-0 pb-md-5'>
        <div class="col-12 mt-3">
            <div class="d-inline-flex align-items-center">
                <status-badge :status='aanmelding.aanmeldingAanmeldingStatusTypes[0]' :klant='klant' />
            </div>
            <h2>{{aanmelding.customer.firstname}} {{achternaam}}</h2>
            <h6>Ingevoerd op {{ aanmelddatum }}</h6>
        </div>
        <div class="w-100 d-flex align-items-end justify-content-between">
            <h5 class="mb-0">Algemene informatie</h5>
            <div class=" d-flex">
                <button
                    class="btn btn-chip mb-2"
                    @click='toggleAllowEdit'
                    >
                    {{ this.aanpassen ? 'Opslaan' : 'Aanpassen' }}
                </button>
            </div>
        </div>
        <div id='summary' class="summary-card w-100" v-if='summary'>
            <div>
                <div class="summary-card-icon-hold">
                    <font-awesome-icon icon="fa-solid fa-calendar-days"/>
                </div>
                <div>
                    <h6>Leeftijd</h6>
                    <h5>{{ leeftijd }}
                        <span v-if="leeftijd < 14">jaar  en {{ maanden }} maanden</span>
                    </h5>
                    <h6>{{  geboortedatum }}</h6>
                </div>
            </div>
            <div>
                <div class="summary-card-icon-hold">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M336 64h-53.88C268.9 26.8 233.7 0 192 0S115.1 26.8 101.9 64H48C21.5 64 0 85.48 0 112v352C0 490.5 21.5 512 48 512h288c26.5 0 48-21.48 48-48v-352C384 85.48 362.5 64 336 64zM192 64c17.67 0 32 14.33 32 32s-14.33 32-32 32S160 113.7 160 96S174.3 64 192 64zM282.9 262.8l-88 112c-4.047 5.156-10.02 8.438-16.53 9.062C177.6 383.1 176.8 384 176 384c-5.703 0-11.25-2.031-15.62-5.781l-56-48c-10.06-8.625-11.22-23.78-2.594-33.84c8.609-10.06 23.77-11.22 33.84-2.594l36.98 31.69l72.52-92.28c8.188-10.44 23.3-12.22 33.7-4.062C289.3 237.3 291.1 252.4 282.9 262.8z"/></svg>
                </div>
                <div>
                    <h6>Cursus</h6>
                    <div v-if="aanpassen">
                        <select class="form-select" v-model="aanmeldingUpdate.cursusId">
                            <option
                            v-for="cursus in this.$store.state.options.cursussen"
                            :key="cursus.id"
                            :value="cursus.id"
                            >
                            {{cursus.titel}}
                            </option>
                        </select>
                    </div>
                    <div v-else>
                        <h5>{{aanmelding.cursus.titel}}</h5>
                        <h6>{{aanmelding.cursus.periode}}</h6>
                    </div>
                </div>
            </div>
            <div>
                <div class="summary-card-icon-hold">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z"/></svg>
                </div>
                <div>
                    <h6>Voorkeursmomenten</h6>
                    <div v-if="aanpassen">

                    </div>
                    <div v-else>
                        <div v-if="aanmelding.hasOwnProperty('preftime1')">
                            <h5><small>#1</small> {{aanmelding.preftime1.dag.description}} {{aanmelding.preftime1.dagdeel.periode}}</h5>
                            <h6><small>#2</small> {{aanmelding.preftime2.dag.description}} {{aanmelding.preftime2.dagdeel.periode}}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="summary-card-icon-hold">
                    <font-awesome-icon v-if='aanmelding.skisnowboardId == 1' icon="fa-solid fa-person-skiing" />
                    <font-awesome-icon v-else-if='aanmelding.skisnowboardId == 2' icon="fa-solid fa-person-snowboarding" />
                    <font-awesome-icon v-else icon="fa-solid fa-child-reaching" />
                </div>
                <div>
                    <h6>Sport</h6>
                    <h5>{{aanmelding.skisnowboard.omschrijving}}</h5>
                </div>
            </div>
            <div>
                <div class="summary-card-icon-hold">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M351.1 416H63.99c-17.6 0-31.1 14.4-31.1 31.1l.0026 31.1C31.1 497.6 46.4 512 63.1 512h288c17.6 0 32-14.4 32-31.1l-.0049-31.1C383.1 430.4 369.6 416 351.1 416zM425 206.9c-27.25-22.62-67.5-19-90.13 8.25l-20.88 25L284.4 111.8c-18-77.5-95.38-125.1-172.8-108C34.26 21.63-14.25 98.88 3.754 176.4L64 384h288l81.14-86.1C455.8 269.8 452.1 229.5 425 206.9z"/></svg>
                </div>
                <div>
                    <h6>Materiaal</h6>
                    <h5>{{aanmelding.materiaal ? aanmelding.materiaal.titel : "Geen materiaal"}}</h5>
                </div>
            </div>
            <div>
                <div class="summary-card-icon-hold">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 32C547.3 32 576 60.65 576 96V128H0V96C0 60.65 28.65 32 64 32H512zM576 416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V224H576V416zM112 352C103.2 352 96 359.2 96 368C96 376.8 103.2 384 112 384H176C184.8 384 192 376.8 192 368C192 359.2 184.8 352 176 352H112zM240 384H368C376.8 384 384 376.8 384 368C384 359.2 376.8 352 368 352H240C231.2 352 224 359.2 224 368C224 376.8 231.2 384 240 384z"/></svg>
                </div>
                <div>
                    <h6>Vrijskikaart</h6>
                    <h5>{{aanmelding.skikaart ? aanmelding.skikaart.skikaart1.split(/[:()]+/)[0] : "Geen vrijskikaart"}}</h5>
                </div>
            </div>
        </div>
        <div v-if="aanmelding.remark !== ''" class="col-md-12 mt-4">
            <h5>Opmerking en aanvullingen van klant </h5>
            <div class="summary-card d-block p-3">

                <p class='mb-0'>{{aanmelding.remark}}</p>
            </div>
        </div>
        <div class="col-md-12 mt-4">
            <h5>Niveau van {{aanmelding.customer.firstname}}</h5>
            <div id='niveau' class="summary-card">
                <div class="span-2">
                    <div class="summary-card-icon-hold">
                        <font-awesome-icon :icon="['fad', 'snowman']" />
                    </div>
                    <div>
                        <h6>In de sneeuw</h6>
                        <div v-if='aanmelding.aanmeldingHistory && aanmelding.aanmeldingHistory.eerder'>
                            <div v-if='aanmelding.aanmeldingHistory.afgelopenSeizoen'>
                                <p class="mb-0">Recent bij de piste (afgelopen 2 seizoenen) {{aanmelding.skisnowboardId  === 1 ? "geskied" : "gesnowboard"}}. Bekend in cursistenplanning.</p>
                            </div>
                            <div v-else>
                                <p class="mb-0">
                                    <span v-if="aanmelding.aanmeldingHistory.laatsteJaar">Voor het laatst in {{aanmelding.aanmeldingHistory.laatsteJaar}} {{aanmelding.skisnowboardId  === 1 ? "geskied" : "gesnowboard"}},</span>
                                    {{aanmelding.aanmeldingHistory.wekenErvaring}} weken sneewervaring.
                                </p>
                            </div>
                        </div>
                        <div v-else>
                            <p class="mb-0">Nog nooit {{aanmelding.skisnowboardId  === 1 ? "geskied" : "gesnowboard"}}.</p>
                        </div>
                    </div>
                </div>
                <div v-if="aanmelding.aanmeldingHistory && aanmelding.aanmeldingHistory.eerder && !aanmelding.aanmeldingHistory.afgelopenSeizoen">
                    <div class="summary-card-icon-hold">
                        <font-awesome-icon :icon="['fad', 'person-ski-lift']" />
                    </div>
                    <div>
                        <h6>Technieken</h6>
                        <ul>
                            <li
                                v-for="(item, index) in aanmelding.aanmeldingTechniek1s"
                                :key="index"
                            >
                            <span class='techniek-check check-true' v-if='item.selected'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                            </span>
                            <span class='techniek-check check-false' v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                            </span>
                            {{item.techniek.techniek.split(/[:()]+/)[0]}}</li>
                        </ul>
                    </div>
                </div>
                <div v-if="aanmelding.aanmeldingHistory && aanmelding.aanmeldingHistory.eerder && !aanmelding.aanmeldingHistory.afgelopenSeizoen">
                    <div class="summary-card-icon-hold">
                        <font-awesome-icon :icon="['fad', 'mountain']" />
                    </div>
                    <div>
                        <h6>Pistes</h6>
                        <ul>
                            <li
                                v-for="(item, index) in aanmelding.aanmeldingPistes"
                                :key="index"
                            >
                            <span class='techniek-check check-true' v-if='item.selected'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                            </span>
                            <span class='techniek-check check-false' v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                            </span>
                            {{item.piste}}</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div class="summary-card-icon-hold">
                        <font-awesome-icon :icon="['fad', 'notes']" />
                    </div>
                    <div id="textarea-holder">
                        <h6>Opmerking op persoonsniveau</h6>
                        <textarea class="form-control mt-1" name="remark" id="" cols="30" rows="2"
                            v-model.lazy="notitie"
                        ></textarea>
                    </div>
                </div>
                <div>
                    <div class="summary-card-icon-hold">
                        <font-awesome-icon :icon="['fad', 'stars']" />
                    </div>
                    <div class="d-block">
                        <div class="d-inline-flex">
                            <div>
                                <h6>Skiniveau</h6>
                                <div v-if="this.$store.state.vergaarbak.niveaus.ski.length > 0">
                                    <select class="form-select" v-model="niveauSki">
                                        <option
                                        v-for="niveau in this.$store.state.vergaarbak.niveaus.ski"
                                        :key="niveau.klasTypeIdGroep"
                                        :value="niveau.klasTypeIdGroep"
                                        :selected="niveau.klasTypeIdGroep == this.$store.state.vergaarbak.currentAanmelding.customer.vermoedelijkNiveauSki"
                                        >
                                        {{niveau.klasTypeIdGroep}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="ms-2 ms-md-3">
                                <h6>Snowboardniveau</h6>
                                <div  v-if="this.$store.state.vergaarbak.niveaus.snowboard.length > 0">
                                    <select class="form-select" v-model="niveauSnowboard">
                                        <option
                                        v-for="niveau in this.$store.state.vergaarbak.niveaus.snowboard"
                                        :key="niveau.klasTypeIdGroep"
                                        :value="niveau.klasTypeIdGroep"
                                        :selected="niveau.klasTypeIdGroep == this.$store.state.vergaarbak.currentAanmelding.customer.vermoedelijkNiveauSnowboard"
                                        >
                                        {{niveau.klasTypeIdGroep}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <h6 class="mt-2">Niveau definitief</h6>
                        <input class="form-control" type="text" name="remark"
                            v-model.lazy="niveauDefinitief"
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="aanmeldingen.filter(a => a.id !== aanmelding.id).length > 0">
            <h5>Andere aanmeldingen in deze familie</h5>
            <div id='aanmelding-container' class="col-md-12">
              <aanmeld-rij
              v-for="aan in aanmeldingen.filter(a => a.id !== aanmelding.id)"
              :key="aan.id"
              :aanmelding='aan'
              :cursusId='aanmelding.cursusId'
              :klant='false'
              class="cursor-pointer"
              @click='aanmeldingClicked($event, aan.id)'
              ></aanmeld-rij>
            </div>
        </div>
    </div>
</template>

<script>
import statusBadge from '@/components/aanmeldingen/StatusBadge.vue'
import AanmeldRij from '@/components/aanmeldingen/AanmeldRij.vue'
import LoaderSpinner from '@/components/utils/LoaderSpinner.vue'

export default {
  components: {
    statusBadge,
    AanmeldRij,
    LoaderSpinner
  },
  props: {
    aanmelding: {
      type: Object,
      required: true
    },
    aanmeldingen: {
      type: Array,
      required: false,
      default: () => []
    },
    klant: {
      type: Boolean,
      required: false,
      default: true
    },
    groupsOverzicht: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      aanpassen: false,
      aanmeldingUpdate: this.aanmelding
    }
  },
  methods: {
    aanmeldingClicked (event, id) {
      this.$router.push({
        name: 'Aanmelding',
        params: {
          id: id
        }
      })
      window.scrollTo(0, 0)
    },
    allowEdit () {
      this.aanpassen = true
      this.$store.dispatch('options/getCursussen')
    },
    disAllowEdit () {
      this.aanpassen = false
    },
    toggleAllowEdit () {
      if (this.aanpassen) {
        this.disAllowEdit()
      } else {
        this.allowEdit()
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.$store.dispatch('vergaarbak/getNiveaus')
  },
  computed: {
    niveauDefinitief: {
      get: function () {
        return this.$store.state.vergaarbak.currentAanmelding.customer.currentLevel
      },
      set: function (value) {
        this.$store.dispatch('vergaarbak/setNiveauDefinitief', {
          aanmeldingId: this.aanmelding.id,
          niveauDefinitief: value
        })
      }
    },
    notitie: {
      get: function () {
        return this.$store.state.vergaarbak.currentAanmelding.customer.remark
      },
      set: function (value) {
        this.$store.dispatch('vergaarbak/setNotitie', {
          aanmeldingId: this.aanmelding.id,
          notitie: value
        })
      }
    },
    niveauSki: {
      get: function () {
        return this.$store.state.vergaarbak.currentAanmelding.customer.vermoedelijkNiveauSki
      },
      set: function (value) {
        this.$store.dispatch('vergaarbak/setNiveau', {
          discipline: 'ski',
          aanmeldingId: this.aanmelding.id,
          niveauId: value
        })
      }
    },
    niveauSnowboard: {
      get: function () {
        return this.$store.state.vergaarbak.currentAanmelding.customer.vermoedelijkNiveauSnowboard
      },
      set: function (value) {
        this.$store.dispatch('vergaarbak/setNiveau', {
          discipline: 'snowboard',
          aanmeldingId: this.aanmelding.id,
          niveauId: value
        })
      }
    },
    aanmelddatum () {
      const signupdate = new Date(this.aanmelding.signupdate)
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      return signupdate.toLocaleDateString('nl-NL', options)
    },
    summary: function () {
      return true
    },
    achternaam: function () {
      return (this.aanmelding.group.prelastname ? this.aanmelding.group.prelastname : '') + ' ' + this.aanmelding.group.lastname
    },
    leeftijd: function () {
      const birthdate = new Date(this.aanmelding.customer.birthdate)
      const ageDifMs = Date.now() - birthdate.getTime()
      const ageDate = new Date(ageDifMs)
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },
    maanden: function () {
      const birthdate = new Date(this.aanmelding.customer.birthdate)
      const ageDifMs = Date.now() - birthdate.getTime()
      const ageDate = new Date(ageDifMs)
      return Math.abs(ageDate.getUTCMonth())
    },
    geboortedatum: function () {
      const birthdate = new Date(this.aanmelding.customer.birthdate)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return birthdate.toLocaleDateString('nl-NL', options)
    }
  }
}
</script>

<style scoped>
#aanmelding-container{
  border-radius: 20px;
  margin-bottom: 100px;
}
@media screen and (max-width: 768px) {
  #aanmelding-container{
    border-radius: 0px;
    margin-bottom: 1rem;
  }
}
    ul{
        padding-left: 0;
    }
    .techniek-check{
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }
    .techniek-check svg{
        margin-bottom: 6px;
        width: 18px;
    }
    .techniek-check.check-true svg{
        fill: #00a65a;
    }
    .techniek-check.check-false svg{
        fill: rgba(0,0,0,.14);
    }
    .summary-card{
        background: #fff;
        border-radius: 15px;
        padding: 12px;
        /* box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; */
        display: inline-flex;
        align-items: center;
    }

    .summary-card h6{
        font-size: 0.8rem;
        margin-bottom: 0;
    }

    .summary-card h5{
        margin-bottom: 0;
    }
    #summary{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        background: var(--background-light);
        height: max-content;
        grid-gap: 1px;
        padding: 0;
        overflow: hidden;
    }
    @media  (max-width: 992px){
        #summary{
            grid-template-columns: 1fr 1fr;
        }
    }
    @media  (max-width: 768px){
        #summary{
            grid-template-columns: 1fr;
        }
    }
    #summary > div, #niveau > div{
        background: #fff;
        height: 100%;
        padding: 12px;
        display: flex;
        align-items: center;
    }

    #niveau > div{
        align-items: start;
    }

    #niveau{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        background: var(--background-light);
        height: max-content;
        grid-gap: 1px;
        padding: 0;
        overflow: hidden;
    }

    #niveau .span-2{
        grid-column: span 2;
    }

    @media  (max-width: 768px){
        #niveau{
            grid-template-columns: 1fr;
        }
        #niveau .span-2{
            grid-column: span 1;
        }

    }

     .summary-card svg{
        height: 25px;
        fill: var(--sp1);
        color: var(--sp1);
    }
    .summary-card-icon-hold{
        width: 37px;
        height: 37px;
        background: var(--sp3);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
    }
    h3{
        font-weight: 300;
        letter-spacing: -1px;
    }
    h4{
        font-weight: 300;
        letter-spacing: -1px;
    }
    p{
        color: rgba(0,0,0,.87);
        font-weight: 500;
    }
    #textarea-holder{
        flex-grow: 1;
    }
    .btn-chip{
        background: rgba(0,0,0,.23);
        border-radius: 100px !important;
        font-weight: 500;
    }
</style>
