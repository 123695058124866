<template>
  <div class='balloon' :class="getBalloonStyle">
      <p class="text mb-0" v-html="opmerking.opmerking"></p>
      <small class="date">
          <span v-if="this.opmerking.userID && this.opmerking.userID !== this.$store.state.auth.userId">
              {{opmerking.user.firstName}}
              &middot;
          </span>
          {{modified}}
      </small>
  </div>
</template>

<script>
import 'animate.css'
import moment from 'moment'
import 'moment/locale/nl'

export default {
  props: {
    opmerking: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      moment: moment
    }
  },
  computed: {
    modified () {
      if (moment(this.opmerking.modified).isSame(moment(), 'day')) {
        return 'Vandaag om ' + moment(this.opmerking.modified).format('HH:mm')
      } else {
        return moment(this.opmerking.modified).format('D MMMM YYYY, HH:mm')
      }
    },
    getBalloonStyle () {
      return this.getOwnerStyle + ' ' + this.getTypeStyle + (this.opmerking.new ? ' animate__animated animate__bounceInUp' : '')
    },
    getOwnerStyle () {
      if (this.opmerking.userID === this.$store.state.auth.userId) {
        return 'owner'
      } else if (this.opmerking.userID) {
        return 'other'
      } else {
        return 'no-owner'
      }
    },
    getTypeStyle () {
      if (this.opmerking.isWijziging) {
        return 'system'
      } else {
        return 'human'
      }
    }
  }

}
</script>

<style scoped>
.balloon{
    max-width: 80%;
    min-width: 200px;
    background: #fff;
    margin-top: 12px;
    padding: 12px 20px 18px;
    position: relative;
    display: inline-flex;
}

.balloon .date{
    float: right;
    position: absolute;
    bottom: 0;
    right: 4px;
    font-size: 0.7rem;
    font-weight: 500;
    width: max-content;
    color: rgba(0,0,0,.54);
}

.balloon .text{
    display: flex;
}

.balloon.owner{
    border-radius: 10px 10px 0 10px;
    margin-left: auto;
    float: right;
    background: var(--skipiste-color);
}
.balloon.owner.human .text{
    color: #fff;
    font-weight: bold;
}

.balloon.owner.human .date{
    color: rgba(255,255,255,.54);
}

.balloon.other{
    border-radius: 10px 10px 10px 0;
    margin-right: auto;
    background: #ffffffbb;
}

.balloon.owner.system{
    width: 100%;
    text-align: center;
}

.balloon.system{
    background: rgba(0,0,0,.05);
    border-radius: 15px;
}

.balloon.other.human{
    background: rgba(255,255,255,.5);
}

.balloon.other.human .text{
    color: rgba(0,0,0,.87);
}

</style>
