<template>
    <div v-if='aanmelding'>
                <div class="col-12 d-inline-flex justify-content-between" id='action'>
                    <div class='d-inline-flex'>
                        <select class="form-select" v-model="aanmelding.aanmeldingAanmeldingStatusTypes[0].aanmeldingStatusType.id">
                            <option
                            v-for="status in statussen"
                            :key="status.id"
                            :value="status.id"
                            :selected="status.id == aanmelding.aanmeldingAanmeldingStatusTypes[0].aanmeldingStatusType.id"
                            >
                            {{status.aanmeldingStatusType1}}
                            </option>
                        </select>
                        <button @click='saveStatus()'
                          :disabled="saveStatusLoading"
                        class='btn btn-light ms-2 d-flex align-items-center'>Opslaan
                        <span v-if="saveStatusLoading" class="ms-2">
                          <font-awesome-icon icon="spinner" spin />
                        </span>
                      </button>
                    </div>
                    <div></div>
                    <button v-if='aanmelding.userId === userId' @click='deSelecteerAanmelding()' class='btn btn-primary'>Deselecteer</button>
                    <button v-else @click='selecteerAanmelding()' class='btn btn-primary'>Toon op cursistenplanning</button>
                </div>
      <div class="container-xl content-grid">
            <div v-if="aanmelding">
              <aanmelding-uitgebreid :loading="loading.aanmelding" :aanmelding='aanmelding' :aanmeldingen='aanmeldingen' :klant='false'></aanmelding-uitgebreid>
            </div>
            <div v-else>
              <p>Aanmelding ophalen</p>
            </div>
            <div id='chat'>
              <aanmelding-chat ref="aanmeldingChat" :id='aanmelding.id'></aanmelding-chat>
            </div>
       </div>
    </div>
</template>

<script>
import Axios from 'axios'
import { createToaster } from '@meforma/vue-toaster'
import AanmeldingUitgebreid from '@/components/aanmeldingen/AanmeldingUitgebreid.vue'
import AanmeldingChat from '@/components/aanmeldingen/AanmeldingChat.vue'
import moment from 'moment'
import 'moment/locale/nl'

export default {
  components: {
    AanmeldingUitgebreid,
    AanmeldingChat
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      aanmelding: false,
      statussen: [],
      aanmeldingen: [],
      loading: {
        aanmelding: false,
        statussen: false,
        aanmeldingen: false
      },
      saveStatusLoading: false
    }
  },
  created: function () {
    moment.locale('nl')
  },
  computed: {
    userId () {
      return this.$store.state.auth.userId
    }
  },
  watch: {
    id: function () {
      this.fetchData()
    }
  },
  async mounted () {
    this.fetchData()
  },
  unmounted () {
    this.$store.dispatch('vergaarbak/unsetCurrentAanmelding')
  },
  methods: {
    moment: function () {
      return moment()
    },
    fetchData: function () {
      this.loading = {
        aanmelding: true,
        statussen: true,
        aanmeldingen: true
      }
      const self = this
      this.$store.dispatch('vergaarbak/getAanmelding', this.id).then(response => {
        self.loading.aanmelding = false
        this.aanmelding = this.$store.state.vergaarbak.currentAanmelding
        Axios.get(process.env.VUE_APP_API_URL + 'aanmelding/aanmeldingvoorgroep/' + this.$store.state.vergaarbak.currentAanmelding.groupId).then(response => {
          self.aanmeldingen = response.data
          self.loading.aanmeldingen = false
        })
      })
      Axios.get(process.env.VUE_APP_API_URL + 'aanmeldingstatustype').then(response => {
        self.statussen = response.data
        self.loading.statussen = false
      })
    },
    saveStatus: function () {
      this.saveStatusLoading = true
      var newStatus = {
        aanmeldingStatusTypeId: this.aanmelding.aanmeldingAanmeldingStatusTypes[0].aanmeldingStatusType.id,
        aanmeldingId: this.id,
        time: moment().format('YYYY-MM-DDTHH:mm:ss')
      }
      const toaster = createToaster({ position: 'bottom-left', duration: 3000 })
      Axios.post(process.env.VUE_APP_API_URL + 'aanmeldingaanmeldingstatustypes', newStatus).then(response => {
        if (response.status === 201) {
          toaster.show('Status opgeslagen')
          this.aanmelding.aanmeldingAanmeldingStatusTypes.unshift(response.data)

          const opmerking = {
            aanmeldingId: this.id,
            userId: this.$store.state.auth.userId,
            modified: moment().format('YYYY-MM-DDTHH:mm:ss'),
            opmerking: 'Status gewijzigd naar ' + this.aanmelding.aanmeldingAanmeldingStatusTypes[0].aanmeldingStatusType.aanmeldingStatusType1,
            isWijziging: true
          }
          this.$store.dispatch('opmerkingen/insert', opmerking).then((res) => {
            this.saveStatusLoading = false
          })
        } else {
          toaster.error('Status niet opgeslagen')
          this.saveStatusLoading = false
        }
      })
    },
    deSelecteerAanmelding () {
      this.aanmelding.userId = null
      const toaster = createToaster({ position: 'bottom-left', duration: 2000 })
      Axios.put(process.env.VUE_APP_API_URL + 'aanmelding/selecteerAanmelding/%20',
        {
          id: this.id
        }
      ).then(response => {
        if (response.status === 204) {
          toaster.show('Groep gedeselecteerd')
        } else {
          toaster.error('Error in selectie')
        }
      })
    },
    selecteerAanmelding: function () {
      this.aanmelding.userId = this.userId
      const self = this
      const toaster = createToaster({ position: 'bottom-left', duration: 2000 })
      Axios.put(process.env.VUE_APP_API_URL + 'aanmelding/selecteerAanmelding/' + self.userId,
        {
          id: this.id
        }
      ).then(response => {
        if (response.status === 204) {
          toaster.show('Zichtbaarheid aangepast')
        } else {
          toaster.error('Error in selectie')
        }
      })
    }
  }
}
</script>

<style scoped>
  .content-grid{
      display: grid;
      max-width: 100vw;
      grid-template-columns: 5fr 3fr;
      background: var(--background-light);
      min-height: 100vh;
      grid-gap: 0px;
  }
  #action{
      background: #fff;
      padding: 12px;
      border-bottom: 1px solid #eee;
  }
  h6{
      font-size: small;
  }
  h5{
      margin-bottom: 2rem;
      font-size: 1.5rem
  }
  .info-card{
      border-bottom: 12px solid var(--background-light);
      padding: 12px;
  }
  .left-column{
      border-right: 1px solid #eaeaea;
  }
  #main-grid{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0;
  }
  @media screen and (max-width: 992px) {
      .content-grid{
        grid-template-columns: 1fr;
      }
      #main-grid{
        grid-template-columns: 1fr;
      }
  }
  #chat{
    height: auto;
    background: var(--background-light);
  }
</style>
